export default () => ({
    links: [],
    sections: [],
    visible: false,
    mobileSwipe: false,
    hidden: true,
    menu: null,
    current: null,

    init() {
        this.menu = this.$root.querySelector('.menu');
        this.mobileSwipe = this.$root.classList.contains('mobile-swipe');
        this.current = this.$root.querySelector('.toggle .current');

        if (window.getComputedStyle(this.$el).getPropertyValue('position') === 'sticky') {
            this.updateTop();

            window.addEventListener('resize', () => this.updateTop());
            window.addEventListener('scroll', () => this.updateTop(), { passive: true });
            window.addEventListener('load', () => this.updateTop());

            if (this.$el.dataset?.toggleClass) {
                window.addEventListener(
                    'scroll',
                    () => {
                        const stickyTop = parseFloat(window.getComputedStyle(this.$el).top);
                        const currentTop = this.$el.getBoundingClientRect().top;
                        this.$el.classList.toggle(this.$el.dataset?.toggleClass, currentTop === stickyTop);
                    },
                    { passive: true }
                );
            }
        }

        this.links = this.$el.querySelectorAll('ul > li > a');

        this.links.forEach((e, k) => {
            let hash = e.getAttribute('href');
            if (hash.startsWith('#')) {
                let id = hash.replace('#', '');
                this.sections[k] = document.getElementById(id);
                e.addEventListener('click', event => {
                    event.preventDefault();
                    this.$dispatch('app:scroll-to', { selector: hash });
                    history.pushState(null, null, hash);
                    this.toggle();
                });
            }
        });

        this.update();

        window.addEventListener('scroll', () => this.update(), { passive: true });
    },

    updateTop() {
        const top = window.headerHeight - 0.5; // chrome bug; pixel gap without it
        this.$el.style.top = `${top}px`;
    },

    toggle() {
        if (this.mobileSwipe) {
            return;
        }
        this.visible = !this.visible;
        this.hidden = !this.hidden;
        this.menu.classList.toggle('hidden');
        this.menu.classList.toggle('flex');
        if (this.current) {
            this.current.classList.toggle('hidden');
        }
    },

    close() {
        if (this.mobileSwipe) {
            return;
        }
        this.visible = false;
        this.hidden = true;
        this.menu.classList.add('hidden');
        this.menu.classList.remove('flex');
        if (this.current) {
            this.current.classList.remove('hidden');
        }
    },

    update() {
        let inView = 0;

        for (let i in this.sections) {
            this.links[i].classList.remove('border-b-white');
            if (this.sections[i] && Math.round(this.sections[i].getBoundingClientRect().top) <= window.scrollOffset) {
                inView = i;
            }
        }

        if (this.links[inView]) {
            this.links[inView].classList.add('border-b-white');
            if (this.current) {
                this.current.innerHTML = this.links[inView].innerHTML;
            }
        }

        this.links.forEach(link => {
            if (!link.getAttribute('href').startsWith('#')) {
                link.classList.remove('border-b-white');

                if (link.getAttribute('href') === window.location.pathname) {
                    link.classList.add('border-b-white');
                }
            }
        });
    },
});
